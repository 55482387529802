import { Trophy } from "lucide-react";
import React from "react";
import Leaderboard from "./Leaderboard";
import NodePool from "./NodePool";
import { Splitscreen } from "@mui/icons-material";
import { useSearchParams } from "react-router";

const Node = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Get the current tab from query params, default to "leaderboard"
  const selectedTab = searchParams.get("tab") || "leaderboard";

  // Function to update query params
  const updateTab = (tab) => {
    setSearchParams({ tab });
  };

  return (
    <>
      <div className={`sub-tabs`}>
        <button
          style={{ borderTopLeftRadius: "8px" }}
          className={`tab sub-tab-updated ${
            selectedTab === "leaderboard" ? "active tab-active-updated" : ""
          }`}
          onClick={() => updateTab("leaderboard")}
        >
          <Trophy className="icon" />
          Leaderboard
        </button>

        <button
          style={{
            borderTopRightRadius: "8px",
          }}
          className={`tab sub-tab-updated ${
            selectedTab === "nodepool" ? "active tab-active-updated" : ""
          }`}
          onClick={() => updateTab("nodepool")}
        >
          <Splitscreen className="icon" />
          Node Pool
        </button>
      </div>

      <div>
        {selectedTab === "leaderboard" && <Leaderboard />}
        {selectedTab === "nodepool" && <NodePool />}
      </div>
    </>
  );
};

export default Node;
