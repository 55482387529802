import React, { useState, useMemo, useEffect } from "react";
import {
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable,
} from "material-react-table";
import { ethers } from "ethers";
import { Tooltip, IconButton, Box } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { Link } from "react-router";
import Loader from "./Loader";
import NodePoolABI from "../abi/NodePool.json";
import { NODEPOOL_ADDRESS, PUBLIC_RPC_URL } from "../lib/config";

const NodePool = () => {
  const [nodes, setNodes] = useState([]);
  const [nodePoolContract, setNodePoolContract] = useState(null);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState(null);
  const [columnVisibility, setColumnVisibility] = useState({
    startPoint: false,
    endPoint: false,
    correctnessPoint: false,
    startCounter: false,
    endCounter: false,
    correctnessCounter: false,
  });

  const statusMapping = ["Ephemeral", "Reserved", "Released"];

  const formatDate = (timestamp) => {
    if (timestamp === 0) return "N/A";
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
  };

  useEffect(() => {
    const init = async () => {
      try {
        // Use public client when wallet is not connected
        const provider = new ethers.JsonRpcProvider(PUBLIC_RPC_URL);
        const nodePool = new ethers.Contract(
          NODEPOOL_ADDRESS,
          NodePoolABI,
          provider
        );
        setNodePoolContract(nodePool);
      } catch (error) {
        console.error("Initialization error:", error);
        setError(`Failed to initialize provider: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    init();
  }, []);

  function decodeNodeEntries(result) {
    return result.map((entry) => {
      // Convert hex string to bytes
      const hexString = entry.startsWith("0x") ? entry.slice(2) : entry;
      const bytes = new Uint8Array(
        hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
      );

      // Get nodeId (first 32 bytes)
      const nodeId = Array.from(bytes.slice(0, 32))
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");

      // Extract nodeAddress (20 bytes after skipping first comma at index 32)
      const addressStart = 33; // Skip comma
      const addressEnd = addressStart + 20;
      const nodeAddressBytes = bytes.slice(addressStart, addressEnd);

      // Convert bytes to Ethereum address format (0x-prefixed)
      const nodeAddress =
        "0x" +
        Array.from(nodeAddressBytes)
          .map((b) => b.toString(16).padStart(2, "0"))
          .join("");

      // Convert remaining bytes to string
      const decoder = new TextDecoder();
      const rest = decoder.decode(bytes.slice(addressEnd));

      const [_, addedTs, reservedTs, releasedTs, status] = rest.split(",");

      return {
        nodeAddress: nodeAddress,
        status: status.replace(/'/g, "").trim(),
        addedTime: parseInt(addedTs),
        reservedTime: parseInt(reservedTs),
        releasedTime: parseInt(releasedTs),
        nodeId: nodeId,
      };
    });
  }

  useEffect(() => {
    setLoading(true);

    if (!nodePoolContract) return;

    nodePoolContract
      .getAllNodeEntries()
      .then((res) => {
        const decodedNodes = decodeNodeEntries(res);
        setNodes(decodedNodes);

        // Dynamically generate columns from the keys of the first node
        if (decodedNodes.length > 0) {
          const keys = Object.keys(decodedNodes[0]);
          const dynamicColumns = keys.map((key) => {
            if (key === "nodeAddress") {
              return {
                accessorKey: key,
                header: "Miner",
                muiTableHeadCellProps: {
                  className: "custom-header-cell", // Apply custom class
                },
                Cell: ({ renderedCellValue, row }) => (
                  <div className="miner-link">
                    <img
                      onClick={() =>
                        window.open(
                          `https://sepolia.arbiscan.io/address/${row.original.nodeAddress}`
                        )
                      }
                      src="/link.png"
                      alt=""
                    />
                    <Tooltip title={row.original.nodeAddress}>
                      <Link
                        to={"/nodestats/" + row.original.nodeAddress}
                        style={{ cursor: "pointer" }}
                      >
                        {row.original.nodeAddress}
                      </Link>
                    </Tooltip>
                  </div>
                ),
              };
            }
            if (key === "status") {
              return {
                accessorKey: key,
                header: "Status",
                muiTableHeadCellProps: {
                  sx: {
                    minWidth: 0,
                    width: "auto",
                    textAlign: "center",
                  },
                },
                muiTableBodyCellProps: {
                  sx: {
                    whiteSpace: "nowrap",
                    width: "auto",
                    maxWidth: "150px",
                    minWidth: 0,
                    textAlign: "left",
                  },
                },
                Cell: ({ cell }) => (
                  <div style={{ whiteSpace: "nowrap", width: "auto" }}>
                    {statusMapping[cell.getValue()] || cell.getValue()}
                  </div>
                ),
              };
            }
            return {
              accessorKey: key,
              header: (key.charAt(0).toUpperCase() + key.slice(1)).replace(
                /([a-z])([A-Z])/g,
                "$1 $2"
              ),
              Cell: ({ cell }) => {
                if (
                  key === "addedTime" ||
                  key === "reservedTime" ||
                  key === "releasedTime"
                ) {
                  return formatDate(cell.getValue());
                }
                return cell.getValue();
              },
            };
          });
          setColumns(dynamicColumns);
        }
      })
      .catch((error) => {
        console.error("Error getting nodes:", error);
        setError(`Failed to get Nodes: ${error.message}`);
        setNodes(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [nodePoolContract]);

  const table = useMaterialReactTable({
    data: nodes,
    columns,
    enablePagination: false,
    enableBottomToolbar: false,

    state: {
      columnVisibility,
    },
    initialState: {
      sorting: [
        { id: "addedTime", desc: true }, // Sort by 'ping_counter' in ascending order
      ],
      density: "compact",
    },
    onColumnVisibilityChange: setColumnVisibility,
    isMultiSortEvent: () => true,
    muiTableBodyProps: {
      sx: () => ({
        "& tr:nth-of-type(odd) > td": {
          backgroundColor: "white",
        },
        "& tr:nth-of-type(even) > td": {
          backgroundColor: "#efefef",
        },
      }),
    },
    renderTopToolbarCustomActions: () => {
      return (
        <div
          className="table-stats"
          style={{ display: "flex", gap: "12px", paddingLeft: "10px" }}
        >
          <div>
            Total nodes: <strong>{nodes.length || "N/A"}</strong>
          </div>
        </div>
      );
    },
    renderToolbarInternalActions: () => {
      return (
        <Box>
          <MRT_ToggleGlobalFilterButton table={table} />
          <Tooltip title="Clear sorting">
            <IconButton onClick={() => table.resetSorting(true)}>
              <Clear />
            </IconButton>
          </Tooltip>
          <MRT_ToggleFiltersButton table={table} />
          <MRT_ShowHideColumnsButton table={table} />
          <MRT_ToggleDensePaddingButton table={table} />
          <MRT_ToggleFullScreenButton table={table} />
        </Box>
      );
    },
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="sessions-container">
      {error ? (
        error
      ) : (
        <div className="sessions-list">
          <div className="session-item" style={{ marginTop: "0" , borderTopLeftRadius: "0"}}>
            <div className="leaderboard-container">
              <MaterialReactTable table={table} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NodePool;
