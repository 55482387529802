import React from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

const TimeIntervalTable = ({ successCounts, successRates, intervals }) => {
  // Helper function to format a timestamp into a readable date and time
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert to milliseconds
    return date.toLocaleString();
  };

  // Prepare data for the table
  const data = intervals.map((intervalStart, index) => {
    const intervalEnd = intervalStart + (intervals[1] - intervals[0] || 0); // Derive interval length from intervals array
    return {
      intervalStartRaw: intervalStart, // Raw timestamp for sorting
      interval: `${formatTimestamp(intervalStart)} - ${formatTimestamp(
        intervalEnd
      )}`,
      total: successCounts[index]?.total || 0,
      success: successCounts[index]?.success || 0,
      failure: successCounts[index]?.failure || 0,
      successRate: successRates[index]?.toFixed(2) || "N/A",
    };
  });

  // Define columns for the table
  const columns = [
    {
      accessorKey: "interval",
      header: "Time Interval",
      enableSorting: true, // Enable sorting for this column
      size: 80,
      Cell: ({ cell }) => (
        <span style={{ whiteSpace: "nowrap" }}>{cell.getValue()}</span>
      ),
      sortingFn: (rowA, rowB) =>
        rowA.original.intervalStartRaw - rowB.original.intervalStartRaw, // Sort by timestamp
    },
    {
      accessorKey: "total",
      enableSorting: false,
      header: "All Count",
      size: 80,
    },
    {
      accessorKey: "success",
      enableSorting: false,
      header: "Successful Count",
      size: 80,
    },
    {
      accessorKey: "failure",
      enableSorting: false,
      header: "Failure Count",
      size: 80,
    },
    {
      accessorKey: "successRate",
      enableSorting: false,
      header: "Success Rate (%)",
      size: 80,
    },
  ];

  const table = useMaterialReactTable({
    data: data,
    columns,
    enableSorting: true,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    initialState: {
      sorting: [
        {
          id: "interval", // Specify the column to be sorted by default
          desc: true, // Set the sorting direction to descending
        },
      ],
    },
    muiTableBodyProps: {
      sx: () => ({
        "& tr:nth-of-type(odd) > td": {
          backgroundColor: "white",
        },
        "& tr:nth-of-type(even) > td": {
          backgroundColor: "#efefef",
        },
      }),
    },
    muiTableBodyCellProps: {
      sx: {
        padding: "8px",
      },
    },
    muiTableContainerProps: {
      sx: {
        borderBottom: "none", // Removes any extra border space for the footer
      },
    },
  });

  return (
    <div className="time-interval-table">
      <MaterialReactTable table={table} />
    </div>
  );
};

export default TimeIntervalTable;
