import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
  Title,
  Legend,
} from "chart.js";
// Register chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
  Title,
  Legend
);
const ComparisonChart = ({ successCounts, intervals }) => {
  // Chart data
  const data = {
    labels: intervals.map((intervalStart) =>
      new Date(intervalStart * 1000).toLocaleString()
    ), // Convert interval start to readable date
    datasets: [
      {
        label: "Successful Timestamps",
        data: successCounts.map((count) => count.success),
        borderColor: "#b5f5c1",
        backgroundColor: "#b5f5c1",
        tension: 0.4,
      },
      {
        label: "Failure Timestamps",
        data: successCounts.map((count) => count.failure),
        borderColor: "#f5b5b5",
        backgroundColor: "#f5b5b5",
        tension: 0.4,
      },
      {
        label: "Total Timestamps",
        data: successCounts.map((count) => count.total),
        borderColor: "#bfdffe",
        backgroundColor: "#bfdffe",
        tension: 0.4,
      },
    ],
  };
  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Timestamps Analysis Over Intervals",
        font: {
          size: 20, // Increase the size as needed
          color: "#000000",
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || "";
            const value = context.raw;
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        display: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Time",
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        title: {
          display: true,
          text: "Count",
        },
        ticks: {
          beginAtZero: true,
        },
      },
    },
  };
  return (
    <div className="chart-card">
      <div className="chart-container">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};
export default ComparisonChart;
