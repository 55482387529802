import React from "react";
import { Activity, Book, UserCheck, Trophy } from "lucide-react";
import { useNavigate, useLocation, Outlet } from "react-router";
import ConnectButton from "../components/ConnectButton";
import { DeviceHub } from "@mui/icons-material";

const Layout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div id="root" className="staking-pools">
      <div className="header">
        <div className="header-logo">
          <img src="/logo.png" alt="Logo" className="logo-image" />
          <h2>Cortensor Dashboard</h2>
        </div>
        <div className="wallet-info">
          <ConnectButton />
        </div>
      </div>
      <main className="main-content">
        <div className="mining-content">
          <div className={`tabs`}>
            <button
              className={`tab tab-updated ${
                pathname.startsWith("/cognitive")
                  ? "active tab-active-updated"
                  : ""
              }`}
              onClick={() => navigate("/cognitive")}
            >
              <Book className="icon" />
              Cognitive (Level 1)
            </button>
            <button
              className={`tab tab-updated ${
                pathname.startsWith("/nodestats")
                  ? "active tab-active-updated"
                  : ""
              }`}
              onClick={() => navigate("/nodestats")}
            >
              <Activity className="icon" />
              Node Stats
            </button>
            <button
              className={`tab tab-updated ${
                pathname.startsWith("/node") &&
                !pathname.startsWith("/nodestats")
                  ? "active tab-active-updated"
                  : ""
              }`}
              onClick={() => navigate("/node")}
            >
              <DeviceHub className="icon" />
              Node
            </button>
            <button
              className="tab tab-updated disabled tab-disabled-updated"
              disabled
              title="Coming Soon - In Development"
            >
              <UserCheck className="icon" />
              Session
            </button>
          </div>
          <div className="content">
            <Outlet />
          </div>
        </div>
      </main>
      <footer className="footer">
        <div className="footer-menu-section">
          <div className="footer-logo-section">
            <div className="footer-img">
              <img src="/logo.png" alt="Logo" className="stake-image" />
              <p>CORTENSOR</p>
            </div>
            <span>
              Unleashing the Future of AI Together.
              <br />
              Built with ❤️ in San Francisco, California.
            </span>
          </div>
          <div className="footer-nav-menu">
            <div className="footer-nav-menu-group">
              <a
                href="https://www.cortensor.network"
                target="_blank"
                rel="noopener noreferrer"
              >
                Home
              </a>
              <a
                href="https://docs.cortensor.network"
                target="_blank"
                rel="noopener noreferrer"
              >
                Documentation
              </a>
            </div>
            <div className="footer-nav-menu-group">
              <a
                href="https://docs.cortensor.network/legal/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              <a
                href="https://docs.cortensor.network/legal/terms-of-use"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>
            </div>
          </div>
        </div>
        <div>
          <br />
        </div>
        <span>Cortensor © 2024. All Rights Reserved.</span>
      </footer>
      <a
        href={`http://twitter.com/share?text=${encodeURIComponent(
          "#Cortensor Dashboard: "
        )}&url=${encodeURIComponent(window.location.href)}`}
        target="_blank"
        className="share-button"
      >
        <img src="/x.jpg" />
        <span className="">Share</span>
      </a>
    </div>
  );
};

export default Layout;
