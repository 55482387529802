import React from "react";
import Chart from "react-apexcharts";

const PingTimeStampChart = ({ series, interval }) => {
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 2,
    },
    plotOptions: {
      heatmap: {
        radius: 0,
        enableShades: false,
        colorScale: {
          ranges: [
            {
              from: 1,
              to: 1,
              color: "#6FBD6F",
              name: "Success",
            },
            {
              from: 0,
              to: 0,
              color: "#E0401E",
              name: "Failed",
            },
            {
              from: 2,
              to: 2,
              color: "#aaaaaa",
              name: "No Data",
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
      style: {
        colors: ["#fff"],
      },
    },
    yaxis: {
      labels: {
        enabled: false,
      },
    },
    xaxis: {
      labels: {
        show: false, // Hide the labels on the X-axis
      },
      tooltip: {
        enabled: false, // Disable the tooltip on the X-axis
      },
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const length = series[seriesIndex].length;
        const minPerBox = 60 / length;
        let endMinute = Math.floor((dataPointIndex + 1) * minPerBox);
        let startMinute = Math.floor(dataPointIndex * minPerBox);

        // Extract hour from yLabel ("25-02-06 08:00")
        let yLabel = w.config.series[seriesIndex].name;
        let startHour = +yLabel.split(" ")[1].split(":")[0]; // Extract hour part
        let endHour = +yLabel.split(" ")[1].split(":")[0]; // Extract hour part
        if (endMinute === 60) {
          endMinute = 0;
          endHour += 1;
        }
        // Ensure minutes are always two digits
        const formattedStartHour = String(startHour).padStart(2, "0");
        const formattedEndHour = String(endHour).padStart(2, "0");
        const formattedStartMinute = String(startMinute).padStart(2, "0");
        const formattedEndMinute = String(endMinute).padStart(2, "0");

        const value = series[seriesIndex][dataPointIndex]; // Value (0,1,2)
        const colorRange = w.config.plotOptions.heatmap.colorScale.ranges.find(
          (range) => value >= range.from && value <= range.to
        );

        return `<div style="padding: 5px; color: black; background: white;
        }; border-radius: 5px;">
          <strong>${
            colorRange ? colorRange.name : "Unknown"
          }</strong> at ${formattedStartHour}:${formattedStartMinute} - ${formattedEndHour}:${formattedEndMinute}
        </div>`;
      },
    },

    legend: {
      show: true,
      customLegendItems: ["Success", "Failed", "No Data"],
    },
  };

  return (
    <div className="ping-chart-card">
      <Chart
        options={options}
        series={series}
        type="heatmap"
        height={
          series.length <= 2
            ? 74 * series.length
            : series.length <= 3
            ? 66 * series.length
            : series.length === 4
            ? 63 * series.length
            : series.length === 5
            ? 58 * series.length
            : 50 * series.length
        }
      />
    </div>
  );
};

export default PingTimeStampChart;
